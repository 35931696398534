<template>
<div>
  <el-row class="bg top">
    <el-col :span="24">
    <span>考勤班级统计：</span>
     <el-select placeholder="请选择班级" v-model="className"
                @change="classChange"
                filterable
                clearable>
      <el-option v-for="(item,index) in dataList2"
                  :key="index"
                  :label="item.className"
                  :value="item.classId"/>
    </el-select>
    <span>课程：</span>
    <el-select placeholder="请选择课程" v-model="courseId"
                @change="curriculumChange"
                filterable
                clearable>
      <el-option v-for="(item,index) in curriculumData"
                  :key="index"
                  :label="item.content"
                  :value="item.id"/>
    </el-select>
   </el-col>
  </el-row>
  <el-row class="bg">
    <el-col :span="24" v-if="this.AttendanceNum.length>0">
      <ve-pie :data="chartData"></ve-pie>
    </el-col>
    <el-col :span="24" v-else>
     <div style="padding:150px;text-align: center;color: #999999;">暂时没有数据</div>
    </el-col>

  </el-row>
  </div>
</template>

<script>
import 'echarts/lib/component/title'
import { getAllClassByMoHu, curriculumList } from '@/api/user.js'
import { getCountLeave } from '@/api/training.js'
  export default {
    name: 'Exhibition',
    data() {
      return {
       className: '',
       dataList2: [],
       curriculumData: [],
       courseId: '',
       AttendanceNum: '',
       chartData: {
        columns: ['NAME', 'VALUE'],
        rows: []
      }
      }
    },
    mounted () {
      this.getAllClassByMoHu()
    },
    methods: {
      // 获取班级信息
      getAllClassByMoHu: function () {
          getAllClassByMoHu(this.listQuery).then(resp => {
            if (resp.data.code === '0') {
              this.dataList2 = resp.data.data.rows // 接收返回的数据
            }
          })
      },
      // 课程提交
       curriculumChange() {
         const query = {
           classId: this.className,
          courseId: this.courseId
        }
        this.chartData.rows = []
          getCountLeave(query).then(resp => {
          if (resp.data.code === '200') {
            this.AttendanceNum = resp.data.data
            for (var i = 0; i < this.AttendanceNum.length; i++) {
               this.chartData.rows.push({
                NAME: this.AttendanceNum[i].NAME,
                VALUE: this.AttendanceNum[i].VALUE
               })
            }
          }
        })
        },
      // 获取课程
      classChange() {
        const query = {
            trainId: this.className
        }
        this.chartData.rows = []
        this.courseId = ''
        curriculumList(query).then(resp => {
            if (resp.data.code === '0') {
                this.curriculumData = resp.data.data.rows // 接收返回的数据
             }
          })
        // getCountLeave(classId).then(resp => {
        //   if (resp.data.code === '200') {
        //     this.AttendanceNum = resp.data.data
        //     for (var i = 0; i < this.AttendanceNum.length; i++) {
        //        this.chartData.rows.push({
        //         NAME: this.AttendanceNum[i].NAME,
        //         VALUE: this.AttendanceNum[i].VALUE
        //        })
        //     }
        //   }
        // })
      }
    }
  }
</script>

<style scoped>
.top{padding: 40px 0px;}
.bg{ background-color: #FFF;}
</style>
